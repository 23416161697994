<template>
  <div class="home">
    <el-row>
      <el-col :span="24">
        <div class="head">
          <el-row>
            <el-col :span="2">
              <img style="padding-left:8px;margin-top:4px" width="80px" height="80px" alt="艾沐佳"
                   @click="switchMainComp" src="https://www.tcpcat.com/r/ajc/amjtm.png">
            </el-col>
            <el-col :span="20">
              <div class="right">
                <h1 style="padding-left:0px;font-weight:300;font-size: 33px">艾沐佳汗蒸床管理系统 —— {{ pagetitle }}</h1>
                <el-menu
                    :default-active=activeIndex
                    class="el-menu"
                    mode="horizontal"
                    background-color="#162f51"
                    text-color="#fff"
                    active-text-color="#ffd04b">
                  <el-menu-item style="font-size: 22px" @click="switchMainComp" index="1">主页</el-menu-item>
                  <el-menu-item style="font-size: 22px" @click="switchDeviceComp" index="2">设备管理</el-menu-item>
                  <el-menu-item style="font-size: 22px" @click="switchUserComp" index="3">用户管理</el-menu-item>
                </el-menu>
                <div>
                  <span><i style="margin-right: 8px;"
                           class="el-icon-user-solid"></i>{{ info.name }}({{  info.pg  }})</span>
                </div>
              </div>
            </el-col>

            <el-col :span="2">
              <el-button type="danger" style="margin-top:23px" @click="exit">退出登陆</el-button>
            </el-col>
          </el-row>

          <!-- <el-divider></el-divider> -->
        </div>
      </el-col>
    </el-row>
    <el-container style="height: 100%; border: 1px solid #eee">
      <el-main>
        <div>
          <keep-alive>
            <component :is="taowa"></component>
          </keep-alive>
        </div>
      </el-main>
    </el-container>
    <div class="foot">Copyright © 2025 艾沐佳汗蒸床管理系统 V1.5.F1C2A114</div>

  </div>
</template>

<script>
import Vue from 'vue'
import {Button} from 'element-ui'

Vue.use(Button)

export default {
  components: {
    devicecomp: (resolve) => require(['../components/devicecomp'], resolve),
    maincomp: (resolve) => require(['../components/maincomp'], resolve),
    usercomp: (resolve) => require(['../components/usercomp.vue'], resolve),
  },
  data() {
    return {
      pagetitle: '主页',
      activeIndex: "1",
      info: {},
      taowa: 'maincomp',
    }
  },
  mounted() {

  },
  created() {
    this.info = this.$store.state.userInfo
    this.activeIndex = "1"

  },
  beforeDestroy() {
  },
  methods: {
    switchMainComp() {
      this.taowa = 'maincomp'
      this.pagetitle = '主页'
      this.activeIndex = "1"
    },
    switchDeviceComp() {
      this.taowa = 'devicecomp'
      this.pagetitle = '设备'
      this.activeIndex = "2"
    },
    switchUserComp() {
      this.taowa = 'usercomp'
      this.pagetitle = '用户'
      this.activeIndex = "3"
    },


    exit() {
      this.$confirm('此操作将退出登陆, 是否继续?', '提示', {
        confirmButtonText: '确定退出',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            this.$notify({
              title: '退出成功',
              message: '请重新登录',
              type: 'success',
            })
            window.sessionStorage.clear()
            this.$router.push('/login')
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消退出',
            })
          })
    },
  },
}
</script>

<style scoped>
.right {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  /* text-align: ce; */
  align-items: center;
  justify-content: space-between;
  margin-right: 25px;
}

.head {
  background-color: rgb(22, 47, 81);
  padding: 5px;
  padding-left: 30px;
  color: white;
  /* padding: 10px; */
}

.el-header {
  background-color: rgb(231, 244, 255);
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}

.foot {
  width: 100%;
  font-size: 14px;
  text-align: center;
}

/* .el-main {
  background: #eee;
} */
</style>
