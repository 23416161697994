import axios from 'axios'
// import { baseUrl } from './env.js'
import {getToken} from '@/api/auth'
import {removeToken} from './auth'
import {Message, Notification, MessageBox} from 'element-ui'
import router from '../router'
// import { Dialog, Toast } from 'vant'
// import store from '@/store'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const service = axios.create({
    baseURL: "https://api.ai-zhineng.com:24311", // url = base url + request url
    // baseURL: "http://127.0.0.1:24311", // url = base url + request url
    timeout: 5000 // request timeout
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 是否需要设置 token
        if (getToken()) {
            config.headers['Authorization'] = getToken() // 让每个请求携带自定义token
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.status === -1) {
            Message({
                message: res.msg || 'Error',
                type: "info"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -2) {
            Message({
                message: res.msg || 'Error',
                type: "error"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -3) {
            Notification({
                title: '警告',
                message: res.msg,
                type: 'warning',
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -4) {
            Notification({
                title: '错误',
                message: res.msg,
                type: 'error',
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -5) {
            MessageBox.alert(res.msg, "警告", {
                type: "warning"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -6) {
            MessageBox.alert(res.msg, "错误", {
                type: "error"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -7) {
            removeToken()
            MessageBox.alert("登录验证失效, 请重新登录", "登录失效", {
                type: "error"
            })
            router.push('/login')
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -8) {
            removeToken()
            MessageBox.alert("权限不足, 请重新登录或联系管理员！", "权限不足", {
                type: "error"
            })
            router.push('/login')
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -9) {
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res
        }
    },
    error => {
        MessageBox.alert("系统错误，请联系技术支持！ (Code:-1)", "系统错误", {
            type: "error"
        })
        return Promise.reject(error)
    }
)

const api = {
    // 登录
    login: (data) => service({
        url: '/api/v5/login',
        method: 'post',
        data
    }),

    // 主页
    card: (data) => service({
        url: '/api/v5/device_card',
        method: 'get',
        params: data

    }),

    // 设备
    get_comments_and_addr: (data) => service({
        url: '/api/v5/get_comments_and_addr',
        method: 'post',
        data
    }),
    edit_device_info: (data) => service({
        url: '/api/v5/edit_device_info',
        method: 'post',
        data
    }),
    enable_device: (data) => service({
        url: '/api/v5/enable_device',
        method: 'post',
        data
    }),
    turn_device: (data) => service({
        url: '/api/v5/turn_device',
        method: 'post',
        data
    }),
    charge_device: (data) => service({
        url: '/api/v5/charge_device',
        method: 'post',
        data
    }),
    device_list: (data) => service({
        url: '/api/v5/device_list',
        method: 'get',
        params: data
    }),
    device_list_v2: (data) => service({
        url: '/api/v5/device_list_v2',
        method: 'get',
        params: data
    }),
    rename_device: (data) => service({
        url: '/api/v5/rename_device',
        method: 'post',
        data
    }),
    // device_detail: (data) => service({
    //     url: '/api/v1/device_detail', //TODO v5
    //     method: 'get',
    //     params: data
    // }),
    username_list: () => service({
        url: '/api/v5/username_list',
        method: 'get',
    }),
    // sel_kids: () => service({
    //     url: '/api/v5/sel_kids',
    //     method: 'get',
    // }),
    device_owner: (data) => service({
        url: '/api/v5/device_owner',
        method: 'get',
        params: data

    }),
    bind_or_not: (data) => service({
        url: '/api/v5/bind_or_not',
        method: 'post',
        data
    }),
    delete_device: (data) => service({
        url: '/api/v5/delete_device',
        method: 'post',
        data
    }),
    device_order_export: (data) => service({
        url: '/api/v5/device_order_export',
        method: 'post',
        data
    }),
    charge_order_export: (data) => service({ // + 2024年1月29日
        url: '/api/v5/charge_order_export',
        method: 'post',
        data
    }),
    // 用户
    update_user_info: (data) => service({
        url: '/api/v5/update_user_info',
        method: 'post',
        data
    }),
    delete_user: (data) => service({
        url: '/api/v5/delete_user',
        method: 'post',
        data
    }),
    create_user: (data) => service({
        url: '/api/v5/create_user',
        method: 'post',
        data
    }),
    user_list: (data) => service({
        url: '/api/v5/user_list',
        method: 'get',
        params: data
    }),
    charge_user: (data) => service({
        url: '/api/v5/charge_user',
        method: 'post',
        data
    }),
    change_password: (data) => service({
        url: '/api/v5/change_password',
        method: 'post',
        data
    }),
    // location
    get_location: (data) => service({
        url: '/api/v5/get_location',
        method: 'get',
        params: data
    }),
    location_commit: (data) => service({
        url: '/api/v5/location_commit',
        method: 'post',
        data
    }),
    // register: (data) => service({
    //     url: '/api/v1/register', //TODO v5
    //     method: 'post',
    //     data
    // }),

    // 权限组
    pg_query: (data) => service({
        url: '/api/v5/pg_query',
        method: 'post',
        data
    }),
    pg_list: () => service({
        url: '/api/v5/pg_list',
        method: 'get',
    }),

}

export default api
